<!-- NOTE:  Maybe using a cursor pointer for hover on the whole row
            is not the way to go. At the moment, clicking on the row
            will take you to the page for the item. This makes sense
            for the photo and name fields but perhaps not for the other
            fields, such as 'Job' or 'Location'. Clicking these fields
            could perhaps filter the table to only show the rows that
            match the value that was clicked.

            E.g. Clicking on a Location field with the value 'Iceland'
            could filter the table to only show items with that location,
            rather than just taking you to the page related to that row.
 -->
<template>

   <tr class="py-4 whitespace-nowrap text-sm font-medium text-gray-900  hover:bg-gray-100 dark-hover:bg-gray-700 cursor-pointer">
<!--      <td><input type="checkbox" class="mx-2 place-self-center"></td>-->
      <td class="xl:pl-16 pl-6 py-2 grid-image-width" v-if="!editMode">
        <img :src="(item.image)?item.image:'https://s3.eu-west-1.amazonaws.com/showdeck.io/static/placeholders/venue_placeholder.png'" class=" object-cover rounded-full xl:h-18 xl:w-18 h-12 w-12 cursor-pointer m-auto" @click="link.func(item.id)" />
      </td>
      <td class="py-2 whitespace-nowrap text-sm text-gray-500 dark:text-white dark:text-white" v-for="(field, idx) in fields" :key="idx">
        <div v-if="field !== link.fieldName || editMode">{{ getAttribute(field) }}</div>
        <div v-else><button @click="link.func(item.id)">{{ getAttribute(field) }}</button></div>
      </td>
      <td v-if="button && !editMode"><button @click="button.func(item.id)">{{button.label}}</button></td>
      <td v-if="secondaryButton && !editMode"><button class="ml-10" @click="secondaryButton.func(item.id)">{{secondaryButton.label}}</button></td>
      <td v-if="editMode">  
        <div class="rounded-md shadow-sm flex items-center  mt-4">
            <input name="won" type="checkbox"
            :checked="item.isPrimary"
                  className="focus:ring-indigo-500 mb-2 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
        </div> 
      </td>
        <td v-show="editDeleteMode==true" class="flex items-center float-right mr-3 h-12 dark:text-white"><PencilIcon  class="w-4 mt-1 mx-1" /> <TrashIcon  class="w-4 mt-1 mx-1" /></td>
    </tr>
</template>

<script>
import { PencilIcon,TrashIcon} from "@heroicons/vue/outline/esm"
export default {
  name: "DynamicTableUnit",
  components:{PencilIcon,TrashIcon},
  props: {
    fields: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true
    },
    editDeleteMode:Boolean,
    button: Object,
    secondaryButton: Object,
    link: Object
  },
  computed:{
    editMode(){
      return this.$store.getters.getDynamicTableContentMode
    }
  },
  methods: {
    getAttribute: function(name){
      for (let prop in this.item){
        if (prop.toLowerCase() === name.toLowerCase()){
          if (this.item[prop] === '') return '-'
          if(typeof this.item[prop] === 'object') {
            try {
              return this.item[prop].join(', ')   // If prop is an Array
            } catch (e) {
              return this.item[prop]
            }
          }
          return this.item[prop]
        }
      }
      return '-'
    },
    openLink(id){
      if(!this.editMode){
        this.link.func(id)
      }
    }
  }
}
</script>
