<template>
  <div class="flex justify-center text-gray-500" :class="[downloadable ? 'px-2' : 'px-0']">
    <div class="flex items-end">
      <div
        class="grid grid-flow-col rounded-md border border-gray-300 h-full place-items-center bg-primary dark:bg-primaryDark dark:text-white">
        <div class="p-2 py-1 justify-center border-r-2 border-gray-500 font-[400]">
          <svg width="19" height="24" viewBox="0 0 19 24" class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200"  fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.99347 13.6714L10.0011 13.6339C10.1494 12.9938 10.3361 12.1955 10.1903 11.4723C10.0932 10.9018 9.69176 10.6795 9.34915 10.6634C8.94517 10.6446 8.58466 10.8857 8.49517 11.2366C8.32642 11.8795 8.47727 12.758 8.75341 13.8777C8.40568 14.7455 7.85085 16.0071 7.44432 16.7571C6.6875 17.167 5.67244 17.7991 5.52159 18.5973C5.49091 18.7446 5.5267 18.9321 5.61108 19.1009C5.70568 19.2884 5.85653 19.433 6.03295 19.5027C6.10966 19.5321 6.2017 19.5562 6.30909 19.5562C6.75909 19.5562 7.48778 19.1759 8.45937 17.4295C8.60767 17.3786 8.76108 17.325 8.90938 17.2714C9.60483 17.025 10.3259 16.7679 10.9778 16.6527C11.6989 17.0571 12.5196 17.317 13.077 17.317C13.6293 17.317 13.8466 16.9741 13.9284 16.7679C14.0716 16.4062 14.0026 15.9509 13.7699 15.7071C13.4324 15.3589 12.6116 15.2679 11.3332 15.4339C10.7043 15.0321 10.2926 14.4857 9.99347 13.6714ZM7.18864 17.7402C6.83324 18.2812 6.56477 18.5518 6.41903 18.6696C6.59034 18.3402 6.92528 17.992 7.18864 17.7402ZM9.42841 11.4321C9.56136 11.6705 9.54347 12.3911 9.44119 12.7554C9.31591 12.2223 9.29801 11.467 9.37216 11.3786C9.39261 11.3812 9.41051 11.3973 9.42841 11.4321ZM9.3875 14.6598C9.66108 15.1554 10.0063 15.5813 10.3872 15.8973C9.83494 16.0286 9.33125 16.2455 8.88125 16.4384C8.77386 16.4839 8.66903 16.5295 8.56676 16.5723C8.90682 15.9268 9.19063 15.1955 9.3875 14.6598ZM13.3659 16.4143C13.3685 16.4196 13.371 16.4277 13.3557 16.4384H13.3506L13.3455 16.4464C13.325 16.4598 13.1153 16.5884 12.2128 16.2161C13.2509 16.1652 13.3634 16.4116 13.3659 16.4143ZM18.2597 6.01607L12.7574 0.251786C12.604 0.0910715 12.3969 0 12.1795 0H1.31818C0.865625 0 0.5 0.383036 0.5 0.857143V23.1429C0.5 23.617 0.865625 24 1.31818 24H17.6818C18.1344 24 18.5 23.617 18.5 23.1429V6.62411C18.5 6.39643 18.4131 6.17679 18.2597 6.01607ZM16.6131 7.01786H11.8011V1.97679L16.6131 7.01786ZM16.6591 22.0714H2.34091V1.92857H10.0625V7.71429C10.0625 8.01265 10.1756 8.2988 10.377 8.50978C10.5784 8.72076 10.8516 8.83929 11.1364 8.83929H16.6591V22.0714Z"
              fill="currentColor" stroke="currentColor" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" />
              
          </svg>
          
        </div>
        <!-- View as Photo Grid -->
        <div class="p-2 justify-center dark:text-white" @click="$emit('onClick:photoView');getGridButton(true)">
          <svg width="25" height="24" viewBox="0 0 25 24"
            class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.5 0H5.94591V5.219H0.5V0ZM10.2983 0H15.7442V5.219H10.2983V0ZM19.2805 0H24.7264V5.219H19.2805V0ZM0.5 9.39H5.94591V14.609H0.5V9.39ZM10.2983 9.39H15.7442V14.609H10.2983V9.39ZM19.2805 9.39H24.7264V14.609H19.2805V9.39ZM0.5 18.781H5.94591V24H0.5V18.781ZM10.2983 18.781H15.7442V24H10.2983V18.781ZM19.2805 18.781H24.7264V24H19.2805V18.781Z"
              fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
        <!-- View as Table -->
        <div class="border-l-2 border-gray-500 px-2  py-1 justify-center" @click="$emit('onClick:photoView');getGridButton(false)">
          <svg width="23" height="24" viewBox="0 0 23 24"
            class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.5 2H21.5M1.5 8.66667H21.5M1.5 15.3333H21.5M1.5 22H21.5" fill="none" stroke="currentColor"
              stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

        </div>
        <!-- Toggle Filters -->
        <div class="p-2 py-1 justify-center border-l-2 border-gray-500">
          <svg width="23" height="22" viewBox="0 0 23 22"
            class="cursor-pointer w-5 hover:text-gray-900 dark-hover:text-gray-200" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M2.125 1.625H20.875V3.875L13.375 12.125V18.125L9.625 20.375V12.125L2.125 3.875V1.625Z"
              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      
      </div>
    </div>
    <!-- <ViewGridIcon v-if="!photoViewOn" class="sm:w-24 w-32 cursor-pointer hover:text-black dark-hover:text-white invisible sm:visible" @click="$emit('onClick:photoView')" />
  <ViewListIcon v-else class="sm:w-24 w-32 cursor-pointer hover:text-black dark-hover:text-white invisible sm:visible" @click="$emit('onClick:photoView')"/>
  <FilterIcon v-if="!filtersOn" class="sm:w-24 w-32 cursor-pointer hover:text-black dark-hover:text-white" @click="$emit('onClick:filters')" />
  <XIcon v-else class="sm:w-24 w-32 cursor-pointer hover:text-black dark-hover:text-white" @click="$emit('onClick:filters')" /> -->
    <DocumentDownloadIcon v-if="downloadable" @click="$emit('download')"
      class="cursor-pointer hover:text-black dark-hover:text-white" />
  </div>
</template>

<script>
import { ref } from "vue";
import { DocumentDownloadIcon } from '@heroicons/vue/outline'

const screenWidth = ref(0)

export default {
  name: "DynamicTableOptions",
  props: {
    downloadable: Boolean,
    photoViewOn: Boolean,
    filtersOn: Boolean,
    getGridButton: Function,
  },
  components: {
    DocumentDownloadIcon
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      // This automatically switches the view to photo view
      // from list view if the screen width is 640px or less.
      // This prevents the user from being stuck in list view
      // when resizing their screen, since the button that
      // toggles between the views gets hidden at 640px or less
      screenWidth.value = window.innerWidth
      if (screenWidth.value <= 640 && !this.photoViewOn) {
        this.$emit('onClick:photoView')
      }
    },
  }
}
</script>
