<template>
    <div :class="outerClasses" class="px-8 mb-8 rounded-md bg-primary dark:bg-primaryDark dark:text-white">
        <div id="selectors" class="grid grid-cols-3 gap-4 hidden ">
            <select-input v-for="(filter, idx) in filters" :key=idx
                @update:value="filter.value=$event; filterFunc()"
                :heading="formatHeading(filter.title)"
                :options="filter.options"
                class="col-span-1" />
        </div>
      <div class="grid grid-cols-2 gap-4 mb-8 items-center ">
        <RangeSliderInput v-for="(filter, idx) in rangeFilters" :key="idx"
                          :title="filter.title"
                          :backTitle="filter.backTitle"
                          :min="filter.min"
                          :max="filter.max"
                          :value="filter.value"
                          @onChange="filter.value=$event; filterFunc()"
                          class="col-span-2 lg:col-span-1"
        />
      </div>
        <div id="selectors" :class="selectorClasses" class="grid gap-4">
            <Multiselect v-for="(filter, idx) in filters" :key=idx
                :heading="formatHeading(filter.title) + '   ' + filter.value.split(';')"
                :options="filter.options"
                :create-active="true"
                :modelValue="goodSplit(';', filter.value)"
                @update:modelValue="filter.value = $event.join(';'); filterFunc()"
                class="col-span-1"
                />
        </div>

    </div>
</template>

<script>
import SelectInput from "@/components/inputs/SelectInput";
import CamelCaseConverter from '@/utils/CamelCaseConverter'
import RangeSliderInput from '@/components/inputs/RangeSliderInput'
import Multiselect from "@/components/inputs/Multiselect";
import goodSplit from "@/utils/PySplit";
export default {
  name: "DynamicTableFilters",
  components: { SelectInput, RangeSliderInput, Multiselect },
  props: {
    filters: Object,
    rangeFilters: Object,
    filterFunc: Function,
    version: {
      required: false,
      default: "1"
    }
  },
  computed: {
    selectorClasses(){
      if(this.version == "2") return "sm:grid-cols-2"
      return "sm:grid-cols-3"
    },
    outerClasses(){
    if(this.version == "2") return "py-2"
      return "py-8"
    }
  },  
  setup(){
    return {
        formatHeading(heading){
            return CamelCaseConverter(heading)
        },
        test(val){
            console.log(val)
        },
        goodSplit,
    }
  }
}
</script>
