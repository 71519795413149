<template>
  <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
    <div class=" py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      <div class="relative bg-white overflow-hidden dark:bg-primaryDark dark:text-white">
        <LoadingSpinner
           class="absolute w-full h-full bg-gray-50 bg-opacity-30"
            v-if="isLoading"
        />
        <transition name="fade">
          <!-- TODO:  Make table have a border and rounded corners. -->
          <div v-if="!photoViewOn" class="mx-8 shadow-md rounded-md my-2">
              <table class="rounded-md min-w-full divide-y divide-gray-200 dark:divide-gray-800">
                <thead class="table-auto bg-gray-50 dark:bg-primaryDark">
                <tr class="px-4">
                  <!-- The single th below is for the image column -->
                  <th scope="col" class=" py-3" v-if="!tabelMode"></th>
                  <th v-for="(field, idx) in fields" :key="idx" scope="col"  class="pr-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white">
                    <button @click="$emit('sort', field)">{{ field }} <svg width="8"   class="h-3 ml-2.5 w-3 inline-block" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M1.72852 4.0063L4.4597 1.33963L7.19088 4.0063M7.19088 8.0063L4.4597 10.673L1.72852 8.0063" stroke="#373F41" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              </svg></button>
                  </th>
                  <th scope="col" class="pr-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider hover:text-black dark:text-gray-300 dark-hover:text-white" 
                      v-if="tabelMode">Show</th>
                  <th v-if="editDeleteMode==true"></th>     
                </tr>
                </thead>
                <tbody >
                <transition-group name="move-unit">
                   <DynamicTableUnit v-for="(item, idx) in items" :key="item.id" :editDeleteMode="editDeleteMode"  :link="link" :item="item" :fields="fields" :button="button" :secondary-button="secondaryButton" :class="idx % 2 === 0 ? 'bg-white dark:bg-primaryDark' : 'bg-gray-50 dark:bg-gray-800'"/>
                </transition-group>
                </tbody>
              </table>
          </div>
          <div v-else :class="innerClasses" class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6 xl:gap-6 lg:gap-2 gap-2 pr-10 pl-10">
            <transition-group name="move-unit">
                <DynamicTablePhotoUnit v-for="item in items" :key="item.id" :editDeleteMode="editDeleteMode" :link="link" :item="item" />
            </transition-group>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
// import {SwitchVerticalIcon} from '@heroicons/vue/solid'
import DynamicTableUnit from "@/components/widgets/dynamicTable/DynamicTableUnit";
import DynamicTablePhotoUnit from "@/components/widgets/dynamicTable/DynamicTablePhotoUnit";
import LoadingSpinner from "@/components/widgets/LoadingSpinner"


export default {
  name: "DynamicTableContent",
  components: {DynamicTablePhotoUnit, LoadingSpinner, DynamicTableUnit},
  props: {
    fields: Array,
    items: Array,
    button: Object,
    secondaryButton: Object,
    link: Object,
    photoViewOn: Boolean,
    editDeleteMode:Boolean,
    innerClasses: {
      default: "my-10"
    }
  },  
  computed:{
    tabelMode(){
      return this.$store.getters.getDynamicTableContentMode
    }
  },
  setup() {
    const store = useStore()
    return {
        isLoading: computed(() => store.getters.isLoading)
    }
  }
}
</script>
