<template>
  <div class="px-10 my-6 pb-4 flex items-center justify-between border-gray-200 sm:px-10 dark:bg-primaryDark">
    <div class="flex-1 flex justify-between sm:hidden">
      <a href="#" @click="$emit('prev')"
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-primaryDark">
        Previous
      </a>
      <a href="#" @click="$emit('next')"
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-primaryDark">
        Next
      </a>
    </div>
    
    <div class="hidden pb-8 sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm text-gray-700 dark:text-white">
          Showing
          {{ ' ' }}
          <span class="font-semibold">{{ firstResultIdx }}</span>
          {{ ' ' }}
          to
          {{ ' ' }}
          <span class="font-semibold">{{ lastResultIdx }}</span>
          {{ ' ' }}
          of
          {{ ' ' }}
          <span class="font-semibold">{{ totalCount }}</span>
          {{ ' ' }}
          results
        </p>
      </div>
      <div class="flex">
        <div class="mr-2 dark:text-white inline-flex items-center">
          <select @change="changeResultCount($event.target.value)"
            class="text-sm dark:bg-primaryDark rounded-md shadow-sm -space-x-px py-2 h-full bg-white border-gray-300 text-gray-500 hover:bg-gray-50">
            <option value="12">12/Page</option>
            <option value="24">24/Page</option>
            <option value="36">36/Page</option>
            <option value="48">48/Page</option>
          </select>
        </div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <a href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark-hover:bg-backgroundDark dark:text-white">
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon v-if="hasPreviousPage" @click="$emit('prev')" class="h-5 w-5" aria-hidden="true" />
          </a>

          <button v-for="i in pages" :key="i.name" :value="i.name" @click="$emit('page', $event.target.value)"
            :class="[i.name === currentPage ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-xs font-medium dark:bg-primaryDark dark:text-white' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium dark:bg-primaryDark dark:text-white dark-hover:bg-backgroundDark']">
            {{ i.name }}
          </button>

          <a href="#"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-xs font-medium text-gray-500 hover:bg-gray-50 dark:bg-primaryDark dark-hover:bg-backgroundDark dark:text-white">
            <span class="sr-only">Next</span>
            <ChevronRightIcon v-if="hasNextPage" @click="$emit('next')" class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "DynamicTablePagination",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    update: Function,
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 7
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      }

      if (this.currentPage === this.pageCount) {
        return this.pageCount - this.maxVisibleButtons + 1;
      }

      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.pageCount
      );
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        if (i > 0) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage
          });
        }
      }

      return range;
    },
  },
  setup() {
    const store = useStore()

    return {
      resultCount: computed(() => store.getters.getDynamicTableResultCount),
      totalCount: computed(() => store.getters.getDynamicTableTotalCount),
      hasNextPage: computed(() => store.getters.getDynamicTableHasNextPage),
      hasPreviousPage: computed(() => store.getters.getDynamicTableHasPreviousPage),
      lastResultIdx: computed(() => store.getters.getDynamicTableLastResultIdx),
      firstResultIdx: computed(() => store.getters.getDynamicTableFirstResultIdx),
      pageCount: computed(() => store.getters.getDynamicTablePageCount),
      currentPage: computed(() => store.getters.getDynamicTableCurrentPage)
    }
  },
  methods: {
    changeResultCount(val) {
      this.$store.commit('setDynamicTableResultCount', val)
      this.$props.update()
    }
  }
}
</script>
