<template>
  <div class="bg-white rounded-b dark:bg-primaryDark">
    <div>
      <div
        v-if="showFilters || showSeachBar "
        id="top-bar"
        class="flex justify-between content-center items-end px-8 p-4 gap-1  sm:gap-6"
      >
        <DynamicTableSearchBar v-if="showSeachBar" :search-func="searchFunc" class="w-full" />
        <div v-if="showFilters" class="flex flex-row items-center sm:ml-1 ml-1">
          <div v-if="filtersOn && filterType == 'default'">
            <button
              @click="reset()"
              class="font-medium text-black hover:text-gray-600 dark:text-white dark-hover:text-gray-400"
            >
              Clear filters
            </button>
          </div>
          <DynamicTableOptions
            :photoViewOn="photoViewOn"
            :filtersOn="filtersOn"
            :downloadable="downloadable"
            @download="$emit('download')"
            v-if="filters"
            :getGridButton="getGridButton"
            @onClick:filters="filtersOn = !filtersOn"
            @onClick:photoView="false"
            
          />
        </div>

        <slot></slot>
      </div>

      <!-- filter system -->
      <div v-if="filterType == 'default'">
        <transition name="slide-down">
          <DynamicTableFilters
            v-if="filters && filtersOn"
            :rangeFilters="rangeFilters"
            :filters="filters"
            :filter-func="update"
          />
        </transition>
      </div>
      <div v-else-if="filterType == 'sideOverlay'">
        <transition name="fade" mode="out-in">
          <DynamicTableFilterOverlay
            @onClose="filtersOn = !filtersOn"
            @onClear="reset"
            :rangeFilters="rangeFilters"
            :filters="filters"
            :filterFunc="update"
            :filterClearFun="reset"
            v-if="filters && filtersOn"
          ></DynamicTableFilterOverlay>
        </transition>
      </div>
      <!-- end filter system -->

      <DynamicTableContent
        @sort="$emit('sort', $event)"
        :photoViewOn="photoViewOn"
        :link="link"
        :fields="fields"
        :items="items"
        :button="button"
        :editDeleteMode="editDeleteMode"
        :secondary-button="secondaryButton"
        :innerClasses=" (!showSeachBar && !showFilters) ? '' : 'my-1'"
      />
      <DynamicTablePagination
        v-if="showFooter"
        @next="$emit('next')"
        @prev="$emit('prev')"
        @page="changePage"
        :update="update"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import DynamicTableSearchBar from "@/components/widgets/dynamicTable/DynamicTableSearchBar";
import DynamicTableContent from "@/components/widgets/dynamicTable/DynamicTableContent";
import DynamicTablePagination from "@/components/widgets/dynamicTable/DynamicTablePagination";
import DynamicTableFilters from "@/components/widgets/dynamicTable/DynamicTableFilters";
import DynamicTableOptions from "@/components/widgets/dynamicTable/DynamicTableOptions";
import DynamicTableFilterOverlay from "./DynamicTableFilterOverlay";
export default {
  name: "DynamicTable",
  components: {
    DynamicTableOptions,
    DynamicTableFilters,
    DynamicTablePagination,
    DynamicTableContent,
    DynamicTableSearchBar,
    DynamicTableFilterOverlay,
  },
  data() {
    return {
      photoViewOn: this.photoViewOnProp
    }
  },
  props: {
    searchFunc: Function,
    reset: Function,
    filters: Object,
    rangeFilters: Object,
    button: Object,
    secondaryButton: Object,
    link: {
      type: Object,
      required: true,
    },
    update: {
      type: Function,
      required: true,
    },
    editDeleteMode:Boolean,
    downloadable: Boolean,
    filterType: {
      required: false,
      default: "default",
    },
    showSeachBar:{
      required: false,
      default: true
    },    
    showFilters:{
      required: false,
      default: true
    },
    showFooter:{
      required: false,
      default: true
    },
    photoViewOnProp:{
      required: false,
      default: true,
      type: Boolean
    }
  },
  setup() {
    const store = useStore();
    const filtersOn = ref(false);
    // const photoViewOn = ref(true);

    return {
      store,
      filtersOn,
      // photoViewOn,
      items: computed(() => store.getters.getDynamicTableItems),
      fields: computed(() => store.getters.getDynamicTableFields),
      isLoading: computed(() => store.getters.isLoading),
      
    };
  },
  methods: {
    changePage(page) {
      this.$store.commit("setDynamicTablePage", page);
      this.$props.update();
    },
    getGridButton(val){
      return this.photoViewOn=val;
     }
  },
  beforeUnmount() {
    this.$store.commit("resetDynamicTableSearchParams");
  },
};
</script>
