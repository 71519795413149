<template>
  <div
    class="relative z-10"
    aria-labelledby="slide-over-title"
    role="dialog"
    aria-modal="true"
  >
    <div class="fixed inset-0 overflow-hidden">
       <div class="absolute inset-0 overflow-hidden">
        <div
          @click="closeModal"
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>
        <div class="mt-20 pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10"
        >
          <div
            class="pointer-events-auto w-screen sm:max-w-md md:max-w-md lg:max-w-lg xl:max-w-xl"
          >
            <div
              class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
            >
              <div class="px-4 sm:px-6">
                <div class="flex items-start justify-between">
                  <h2
                    @click="filterClearFun"
                    class="text-lg cursor-pointer font-medium text-gray-900"
                    id="slide-over-title"
                  >
                    {{ title }}
                  </h2>
                  <div class="ml-3 flex h-7 items-center">
                    <button
                      @click="closeModal"
                      type="button"
                      class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span class="sr-only">Close panel</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
              <div class="relative mt-6 flex-1 px-4 sm:px-6">
                <div class="absolute inset-0 px-4 sm:px-6">
                  <dynamic-table-filters
                    :rangeFilters="rangeFilters"
                    :filters="filters"
                    :filterFunc="filterFunc"
                    version="2"
                  >
                  </dynamic-table-filters>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicTableFilters from "./DynamicTableFilters";
import { XIcon } from "@heroicons/vue/outline";
export default {
  name: "TableFilterOverlay",
  components: {
    DynamicTableFilters,
    XIcon,
  },
  props: {
    title: {
      required: false,
      type: String,
      default: "Clear filters",
    },
    rangeFilters: {
      required: true,
      type: Object,
    },
    filters: {
      required: true,
    },
    filterFunc: {
      required: true,
      type: Function,
    },
    filterClearFun: {
      required: true,
      type: Function,
    },
  },
  methods: {
    async closeModal() {
      this.open = false;
      // function delay(ms) {
      //   return new Promise((resolve) => setTimeout(resolve, ms));
      // }
      // await delay(600);
      this.$emit("onClose");
    },
  },
};
</script>
