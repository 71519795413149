/**
 * Split like it was supposed to work. If the string is empty then it returns an empty list, not an
 * list with 1 empty argument.
 * 
 * More Python features can easily be implemented but this was the main behavior of the python
 * split I needed so I leave following the rest of the Python split rules to someone else when
 * they need those features.
 *
 * Python behavior: https://docs.python.org/3/library/stdtypes.html#str.split
 */
export default (separator, str) => str === "" ? [] : str.split(separator)

