<template>
  <div class="flex-wrap w-full sm:w-full">
    <div class=" flex rounded-md shadow-sm">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input v-model="searchString" @input="searchFunc(searchString)" type="text" id="searchCusrome"  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-md pl-10 sm:text-sm border-gray-300 dark:bg-primaryDark dark:text-white" placeholder="Search..." />
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import { SearchIcon } from '@heroicons/vue/solid'

export default {
  name: "DynamicTableSearchBar",
  components: { SearchIcon },
  props: {
    searchFunc: Function
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    console.log(urlParams.has('search')); // true
    console.log(urlParams.get('search')); // true
    var query=urlParams.get('search'); // "MyParam"
      if(query){ 
        this.searchString="";
        this.searchString=query;
        this.searchFunc(query);
      }
    
  },
  setup() {

    const searchString = ref('')
    return {
      searchString,
    }
  },methods:{
      
  }
}
</script>
