<template>
    <div class="col-span-1 flex flex-col items-center cursor-pointer hover:bg-gray-100 dark-hover:bg-gray-800 rounded-lg py-4"
    @click="link.func(item.id)">
    <img :src="item.image"
      class="object-cover rounded-full flex items-center justify-center sm:h-32 sm:w-32 h-24 w-24" />
    <p class="font-semibold text-center sm:text-sm text-xs line-clamp-3 my-1">{{ item[fields[0]] }}</p>
    <!-- <p class="text-gray-400 italic  sm:text-sm text-xs line-clamp-1 w-full text-center">{{ item[fields[1]] }}</p> -->
  </div>

</template>

<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "DynamicTablePhotoUnit",
  props: {
    item: Object,
    link: Object
  },
  setup() {
    const store = useStore()

    return {
      fields: computed(() => store.getters.getDynamicTablePhotoFields)
    }
  }
}
</script>
